import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"

import { graphql } from "gatsby"

const PrivacyPolicy = props => {
  return (
    <>
      <Header
        locale={props.data.strapiPrivacyPolicy.locale}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiPrivacyPolicy.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
      />
      <Layout>
        <Seo
          lang={props.data.strapiPrivacyPolicy.locale}
          description={props.data.strapiPrivacyPolicy.Heading}
          title={props.data.strapiPrivacyPolicy.Heading}
        />
        <section className="text-container">
          <h1 className="block text-transparent bg-clip-text bg-gradient-to-r from-maroon to-orange-red">
            {props.data.strapiPrivacyPolicy.Heading}
          </h1>
          <div>
            <ReactMarkdown
              source={props.data.strapiPrivacyPolicy.Content.data.Content}
              escapeHtml={false}
            >
              {props.data.strapiPrivacyPolicy.Content.data.Content}
            </ReactMarkdown>
          </div>
        </section>
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        locale={props.data.strapiPrivacyPolicy.locale}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiPrivacyPolicy(locale: { eq: $locale }) {
      Content {
        data {
          Content
        }
      }
      Heading
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

export default PrivacyPolicy
